.app-main-header {
  background-color: #ff5722 !important;
  color: #fff !important;
}
.tab-container {
  overflow: hidden;
  border-radius: 5px;
}
.tabs {
  display: flex;
  justify-content: space-between;
}
.tab-panes .tab-pane {
  display: none;
}
.tab-panes .active {
  display: block;
}
.tab.active {
  background-color: #0370b7;
  color: #fff !important;
  /* padding: 20px; */
  transition: 0.5s all;
}
.tab-container {
  background-color: white !important;
  margin-bottom: 30px !important;
  line-height: 50px;
  height: 50px;
  transition: 0.5s all;
}
.tabs a {
  font-size: 15px !important;
  font-weight: 600;
  padding: 0 10px;
  flex: 1;
  text-align: center;
  display: inline-block;
  text-decoration: none !important;
  margin: 0 10px !important;
  color: #252525 !important;
}
ul.nav-menu li.menu.open .sub-menu li > a.active,
.jr-card-thumb {
  background-color: #0370b7 !important;
}

.app-sidebar .side-nav ul.nav-menu li button,
.app-sidebar .side-nav ul.nav-menu li a {
  padding: 10px 20px 10px 20px;
  color: #000;
}
.app-logo-content.d-flex.align-items-center.justify-content-center {
  /* background-image: url('assets/images/background.jpg'); */
  background-color: #0a0a0a;
}
.MuiListItemIcon-root {
  min-width: 30px !important;
}
.app-sidebar .side-nav {
  background: #fff !important;
  box-shadow: 5px 10px 18px #ced4da;
}
div.mini-drawer .app-main-header.custom-color {
  background: #0370b7 !important;
}

.app-sidebar ul.nav-menu li.menu.open > a,
.app-sidebar ul.nav-menu li a.active,
.app-sidebar .side-nav ul.nav-menu li a:hover,
.cbutton:hover {
  background: #0370b7;
  color: #fff;
}
label.upload-btn {
  background: #0370b7;
}
ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus,
ul.nav-menu li.menu.open .sub-menu li > a.active:before,
ul.nav-menu li.menu .sub-menu li > a:focus:before,
ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #0370b7 !important;
  color: #000;
}

label.MuiInputLabel-shrink-200,
label.MuiInputLabel-focused-187 {
  color: #0370b7;
}
.page-heading i.zmdi-hc-fw {
  color: #0370b7;
}
.app-main-content .jr-btn,
button.CBmargin,
.app-login-form button {
  background-color: #0370b7;
  color: white;
}
.app-main-content .jr-btn:hover,
.app-login-form button:hover {
  background: #0370b7 !important;
  text-decoration: none;
}
.app-container.fixed-drawer .app-main-header {
  background: #0370b7 !important;
}

div#form-dialog-title {
  background: #0370b7;
}

/* Status labels*/

span.confirmed,
span.created {
  background: #ffcb00e0;
}

span.searching,
span.label.draft {
  background: #e0b91ffc;
}

span.started,
span.label.new {
  background: #e0491f;
}

span.inrouteDrop,
span.label.rx {
  /* background: #b276e3; */
  background: #de5ca3;
  padding-left: 12px;
  padding-right: 12px;
}

span.inroutePick,
span.label.otc {
  background: #972ded;
}
span.label {
  background: gray;
  padding-left: 8px;
  padding-right: 8px;
}
span.pending {
  background: orange;
}
span.picked {
  background: dodgerblue;
}
span.completed {
  background: limegreen;
}
span.assigned {
  background: turquoise;
}
span.return_on_route {
  background: rgb(245, 121, 142);
}
span.returned {
  background: rgb(194, 43, 194);
}

span.In.Stock,
span.Online,
span.active,
span.approved,
span.label.confirmed,
span.Yes,
span.yes {
  background: green;
}
span.stripe {
  background: #1e70b7;
}
span.cash {
  background: #d15c0c;
}

span.cancelled,
span.label.Out.Of.Stock,
span.cancel,
span.rejected,
span.blocked,
span.inactive,
span.No,
span.declined,
span.no {
  background: #fd2121;
}
span.DestinationInroute,
span.PickupInroute,
span.inrouteDrop {
  background: #b276e3;
}

span.FindingTrips,
span.inroutePick,
span.FindingDrivers {
  background: #972ded;
}
span.Offline {
  background: #eb4d4d;
}

/* custom styling for create orders tab */
.input_and_label {
  display: flex;
  align-items: flex-end;
}

.label_text {
  font-size: 16px;
}
.left_gap,
.create_order {
  padding: 0 1rem 0.7rem;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #f5f5f5;
}
.assign_order {
  padding: 1.5rem 1rem 0.7rem;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #f5f5f5;
}
.assign_order .MuiFormControl-root {
  margin: 0 !important;
}
.left_gap .MuiFormControl-root {
  margin: 0 !important;
}

.hide_label .MuiInputLabel-formControl {
  display: none !important;
}

h3.header-title {
  background: #1e70b7;
  color: white;
  padding: 5px;
  margin-top: 16px;
}

.user-detail.notificationicon {
  position: relative;
}
.user-detail.notificationicon i {
  font-size: 23px;
}

.not-count {
  top: -8px;
  right: 19px;
  /* transform: scale(1) translate(50%, -50%); */
  transform-origin: 100% 0%;
  color: #fff;
  height: 20px;
  display: flex;
  padding: 0 6px;
  z-index: 1;
  position: absolute;
  flex-wrap: wrap;
  font-size: 0.75rem;
  min-width: 20 px;
  box-sizing: border-box;
  transition: transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  align-items: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  line-height: 1;
  align-content: center;
  border-radius: 10px;
  flex-direction: row;
  justify-content: center;
  background-color: rgb(246 73 73);
}

.react-datetime-picker {
  display: inline-flex;
  position: relative;
}

.react-datetime-picker,
.react-datetime-picker *,
.react-datetime-picker *:before,
.react-datetime-picker *:after {
  box-sizing: border-box;
}

.react-datetime-picker--disabled {
  background-color: #f0f0f0;
  color: #6d6d6d;
}

.react-datetime-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: thin solid rgb(160, 160, 160) !important;

  padding: 5px 10px;
}

.react-datetime-picker__inputGroup {
  min-width: calc(4px + (4px * 3) + 0.54em * 6 + 0.217em * 2);
  flex-grow: 1;
  padding: 0 2px;
}

.react-datetime-picker__inputGroup__divider {
  padding: 1px 0;
  white-space: pre;
}

.react-datetime-picker__inputGroup__divider,
.react-datetime-picker__inputGroup__leadingZero {
  display: inline-block;
  margin: 0 5px;
}

.react-datetime-picker__inputGroup__input {
  min-width: 0.54em;
  height: calc(100% - 2px);
  position: relative;
  padding: 1px;
  border: 0;
  background: none;
  color: currentColor;
  font: inherit;
  box-sizing: content-box;
  appearance: textfield;
}

.react-datetime-picker__inputGroup__input::-webkit-outer-spin-button,
.react-datetime-picker__inputGroup__input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

.react-datetime-picker__inputGroup__input:invalid {
  background: rgba(255, 0, 0, 0.1);
}

.react-datetime-picker__inputGroup__input--hasLeadingZero {
  margin-left: -0.54em;
  padding-left: calc(1px + 0.54em);
}

.react-datetime-picker__inputGroup__amPm {
  font: inherit;
  appearance: menulist;

  text-transform: uppercase;
}

.react-datetime-picker__button {
  border: 0;
  background: transparent;
  padding: 4px 6px;
}

.react-datetime-picker__button:enabled {
  cursor: pointer;
}

.react-datetime-picker__button:enabled:hover .react-datetime-picker__button__icon,
.react-datetime-picker__button:enabled:focus .react-datetime-picker__button__icon {
  stroke: #0078d7;
}

.react-datetime-picker__button:disabled .react-datetime-picker__button__icon {
  stroke: #6d6d6d;
}

.react-datetime-picker__button svg {
  display: inherit;
}

.react-datetime-picker__calendar,
.react-datetime-picker__clock {
  z-index: 1;
}

.react-datetime-picker__calendar--closed,
.react-datetime-picker__clock--closed {
  display: none;
}

.react-datetime-picker__calendar {
  width: 350px;
  max-width: 100vw;
}

.react-datetime-picker__calendar .react-calendar {
  border-width: thin;
}

.react-datetime-picker__clock {
  display: none;
  width: 200px;
  height: 200px;
  max-width: 100vw;
  padding: 25px;
  background-color: white;
  border: thin solid #a0a096;
}

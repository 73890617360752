/* This is the stylesheet for the Navigation Bar in the Sign In & Sign Up page */
/* The Navigation Bar is located in src/containers/Auth.js */


/* html and body tags */
html, body {
	height: 100%;
}

/* The container for the whole page */
.auth-container {
	height: 100%;
	background: white;
}

/* The navigation bar */
.left-sidebar {
  background: #f5f5f5;
  text-align: center;
}

/* The small container for the Sign In / Sign Up form */
form {
  width: 100%;
}

/* The container around the Sign In form */
.app-login-content {
  padding: 15%;
  width: 100%;
  overflow-y: scroll;
  max-height: 100vh;
}

/* The container around the Sign Up form */
.app-signup-content {
  padding: 5%;
  overflow-y: scroll;
  width: 100%;
  max-height: 100vh;
}

/* The text in the navigation bar */
.sidebars h3 {
	font-size: 30px;
	font-weight: 700;
}

.auth-tab {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* The active link text in the navigation bar */
h3.active, p.active {
  color:#84c225;
  /* padding: 20px 0; */
  background-color: white;
}

/* The white triangle in the navigation bar
h3.active::after {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-right: 22px solid #fff;
  content: "";
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
} */

/* The large container for the Sign In and Sign Up pages */
.row.sidebars {
	height: 100%;
}

/* The container in the navigation bar with the logo, Sign In and Sign Up links */
.left-sidebar .col-md-12 {
  padding: 35px 0;
  cursor: pointer;
}

/* The container for the logo */
.logo-div {
  text-align: center;
}

/* The logo image */
.logo-div img {
	width: 200px;
}



/* Styles applied when the screen width is 767 pixels or smaller */
@media (max-width: 767px) {
  
  /* Helps set height of navigation bar */
  .sidebars {
    max-height: 65px;
  }

  .left-sidebar h3{
    font-size: medium;
  }

  /* Set height of navigation bar and make it horizontal */
  .left-sidebar .col-md-12 {
    padding: 15px;
    padding-bottom: 0px;
    flex: 1;
    height: 65px;
  }

  /* Add padding to the bottom of the Sign In form */
  .app-login-content {
    padding-bottom: 100px;
  }

  /* Add padding to the bottom of the Sign Up form */
  .app-signup-content {
    padding-bottom: 100px;
  }

  /* Resize and center the */
  .logo-div img {
    width: 100px;
    margin-top: -3px;
  }

  /* Hide the white triangle in the navigation bar */
  /* h3.active::after {
    border-right: 0px;
  } */
  .auth-tab {
    height: 70px;
  }
}


/* Styles applied when the screen is shorter than 500px and wider than 768px */
@media (max-height: 500px) and (min-width: 768px) {

  /* Make the logo smaller */
  .logo-div img {
    width: 150px;
  }

  /* Reduce the padding in the navigation bar */
  .left-sidebar .col-md-12 {
    padding: 25px 15px;
    cursor: pointer;
  }
}


